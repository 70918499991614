/* font */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6Z11lFc-K.woff2) format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}
/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6Z1JlFc-K.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6Z1xlFQ.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/worksans/v18/QGYsz_wNahGAdqQ43Rh_c6Dpp_k.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/worksans/v18/QGYsz_wNahGAdqQ43Rh_cqDpp_k.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/worksans/v18/QGYsz_wNahGAdqQ43Rh_fKDp.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 500;
  src: url(https://fonts.gstatic.com/s/worksans/v18/QGYsz_wNahGAdqQ43Rh_c6Dpp_k.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 500;
  src: url(https://fonts.gstatic.com/s/worksans/v18/QGYsz_wNahGAdqQ43Rh_cqDpp_k.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 500;
  src: url(https://fonts.gstatic.com/s/worksans/v18/QGYsz_wNahGAdqQ43Rh_fKDp.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* ----------------------------------------------------- */

*{
  margin: 0;
  padding: 0;
  font-family: 'Poppins', Work Sans;
}

/* app */
body,
html,
#root {
  height: 100%;
}

html {
  font-size: 18px;
}

h1 {
  padding: .77rem 0;
  background-color: #010c3f;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-size: 1.88rem;
  color: #fff;
}

h1 .highlight {
  color: #6c41ec;
}

h2 {
  font-family: 'Poppins', sans-serif;
  font-size: 1.55rem;
}

.App {
  height: 100%;
  padding: 0 17% 10% 17%;
  background-image: url('./Components/App/background_photo_desktop.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  font-family: 'Work Sans', sans-serif;
  font-weight: 500;
  color: #fff;
}

.App-playlist {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

@media only screen and (max-width: 1020px) {
  .App-playlist {
    align-items: center;
    flex-direction: column;
  }
}
/* End App css */

/* search bar */
.SearchBar {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 6.94rem;
  margin-bottom: 6.33rem;
}

.SearchBar input {
  width: 287px;
  padding: .88rem 0;
  border: 1px solid #fff;
  border-radius: 3px;
  margin-bottom: 2.22rem;
  color: #010c3f;
  text-align: center;
  font-size: 1rem;
}

.SearchBar input:focus {
  outline: none;
}

.SearchButton {
  cursor: pointer;
  width: 8.11rem;
  padding: .77rem 0;
  border-radius: 54px;
  background-color: #010c3f;
  text-align: center;
  font-size: .833rem;
  transition: background-color .25s;
  border: 0px;
  color: #fff;
  font-weight: 500;
}

.SearchButton:hover {
  background-color: rgba(108, 65, 233, .7);
}
/* End searchbar */

/* search result bar*/
.SearchResults {
  width: 50%;
  height: 950px;
  overflow-y: scroll;
  padding: .88rem;
  background-color: rgba(1, 12, 63, 0.7);
  box-shadow: 0 4px 2px 2px #000000;
}

::-webkit-scrollbar {
    width: 0px;
    background: transparent;
}

@media only screen and (max-width: 1020px) {
  .SearchResults {
    width: 90%;
    margin-bottom: 2rem;
  }
}
/* End search result bar  */
/* Playlist  */
.Playlist {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  width: 37%;
  max-height: 950px;
  padding: 2.27rem 1.16rem;
  background-color: rgba(1, 12, 63, 0.7);
  box-shadow: 0 4px 2px 2px #000000;
}

.Playlist input {
  width: 100%;
  border: 0;
  outline: 0;
  background: transparent;
  border-bottom: 1px solid #6f6f6f;
  font-family: 'Poppins', sans-serif;
  font-size: 1.55rem;
  color: #fff;
}

.Playlist-save {
  cursor: pointer;
  width: 10rem;
  padding: .77rem 0;
  border-radius: 54px;
  border-width: 0px;
  margin-top: 1.27rem;
  background-color: #6c41ec;
  text-align: center;
  font-size: .83rem;
  transition: background-color .25s;
  color: #fff;
  font-weight: 500;
}

.Playlist-save:hover {
  background-color: rgba(108, 65, 233, .7);
}

::-webkit-scrollbar {
    width: 0px;
    background: transparent;
}

@media only screen and (max-width: 1020px) {
  .Playlist {
    width: 90%;
  }
}
/* End Playlist  */

/*  Track List  */
.TrackList {
  width: 100%;
}
/* End Track List   */

/*  Track  */
.Track {
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(256, 256, 256, 0.8);
}

.Track-action {
  cursor: pointer;
  padding: .5rem;
  font-size: 1.05rem;
  transition: color .25s;
  border: 0px;
  background-color: rgba(0, 0, 0, 0);
  color: #fff;
}

.Track-action:hover {
  color: rgba(265, 265, 265, .5);
}

.Track-information {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 72px;
}

.Track-information h3 {
  margin-bottom: .22rem;
}

.Track-information p {
  font-size: .83rem;
  font-weight: 300;
  color: rgba(256, 256, 256, 0.8);
}
/* End Track    */